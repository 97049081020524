/*
@File: Exolot - Multipurpose Landing Page HTML5 Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Subscribe Area CSS
** - Welcome Area CSS
** - About Area CSS
** - Services Area CSS
** - Team Area CSS
** - Portfolio Area CSS
** - Features Area CSS
** - Feedback Area CSS
** - Pricing Area CSS
** - Blog Area CSS
** - Work With Us Area CSS
** - Partner Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Right Side Modal CSS
** - Go Top CSS

*/
/*================================================
Default CSS
=================================================*/

:root {
  --rosa: #9e5099;
  --celeste: #addbe0;
  --azul: #546eb3;
  --lila: #4f4093;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i");
body {
  padding: 0;
  margin: 0;
  font-display: "Poppins", sans-serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
h1,
h2,
h3,
h4,
h5,
h6 p {
  font-family: "Ubuntu", sans-serif;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

a {
  display: inline-block;
  outline: 0 !important;
  text-decoration: none !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

p {
  font-size: 15px;
  line-height: 1.8;
  color: #8c8b8b;
}

img {
  max-width: 100%;
}

.bg-f9f9f9 {
  background: #f9f9f9;
}

/*btn*/
.btn {
  font-weight: 600;
  font-size: 15px;
  border: none;
  padding: 10px 33px;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: 50px;
}

.btn:focus {
  outline: 0;
}

button:focus {
  outline: 0;
}

.btn-primary {
  color: #ffffff;
  background-color: var(--rosa);
  border-color: var(--rosa);
}

.btn-primary:hover,
.btn-primary:focus {
  background: var(--lila);
  color: #ffffff;
  border-color: var(--lila);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: var(--azul);
  color: #ffffff;
  border-color: var(--azul);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  opacity: 1;
  background-color: var(--lila);
  border-color: var(--lila);
}

.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus {
  background: var(--azul);
  color: #ffffff;
  border-color: var(--azul);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/*owl carousel btn*/
.owl-theme .owl-nav {
  margin-top: 0;
}

.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  left: 40px;
  top: 45%;
  font-size: 30px !important;
  width: 60px;
  height: 60px;
  text-align: center;
  color: #ffffff !important;
  line-height: 50px !important;
  margin: 0;
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 30px !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--rosa) !important;
  color: #ffffff !important;
}

.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 40px;
  cursor: pointer;
}

.owl-theme .owl-nav .owl-prev {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  line-height: 50px;
  cursor: pointer;
}

.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 40px !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
  margin: 0 5px !important;
  background: #d7d7d7 !important;
  border-radius: 50% !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span {
  background: var(--azul) !important;
}

/*form control*/
.form-control {
  height: 45px;
  padding: 15px;
  font-size: 15px;
  color: #000000;
  background-color: #eeeeee;
  border: none;
  border-radius: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #eeeeee;
  outline: 0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/*section title*/
.section-title {
  text-align: center;
  margin-bottom: 60px;
  margin-top: -8px;
  font-family: "Ubuntu", sans-serif;
}

.section-title span {
  color: #4f4093;
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
}

.section-title h2 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 15px;
}

.section-title h2 b {
  font-weight: 700;
}

.section-title p {
  font-size: 16px;
  max-width: 535px;
  margin: 0 auto;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar {
  position: fixed;
  width: 100%;
  height: auto;
  padding: 20px 0;
  left: 0;
  top: 0;
  background-color: transparent !important;
  z-index: 999;
}

.navbar.navbar-light.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 15px 0;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.navbar-light .navbar-brand {
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
}

.navbar-light .navbar-brand span {
  font-weight: 700;
  color: var(--rosa);
}

.navbar-light .navbar-brand:hover {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-item {
  padding: 0 14px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: var(--rosa);
}

.navbar-light .navbar-nav .nav-item .nav-link span {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 3px;
  background: var(--rosa);
  right: 0;
  top: -3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.navbar-light .navbar-nav .nav-item .nav-link span::before,
.navbar-light .navbar-nav .nav-item .nav-link span:after {
  content: "";
  width: 25px;
  height: 3px;
  background: var(--rosa);
  position: absolute;
  right: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.navbar-light .navbar-nav .nav-item .nav-link span::before {
  top: -7px;
}

.navbar-light .navbar-nav .nav-item .nav-link span::after {
  bottom: -7px;
}

.navbar-light .navbar-nav .nav-item .nav-link:hover span::before,
.navbar-light .navbar-nav .nav-item .nav-link:hover span::after {
  width: 35px;
}

.navbar-light .navbar-nav .nav-item .nav-link.active {
  color: var(--rosa);
}

.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: var(--rosa);
}

.navbar-light .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

.navbar-light.is-sticky .navbar-brand {
  color: #313131;
}

.navbar-light.is-sticky .navbar-nav .nav-item .nav-link {
  color: #313131;
}

.navbar-light.is-sticky .navbar-nav .nav-item .nav-link:hover {
  color: var(--rosa);
}

.navbar-light.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: var(--rosa);
}

.navbar-light.is-sticky .navbar-nav .nav-item.active .nav-link {
  color: var(--rosa);
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 100vh;
  overflow: hidden;
  background-color: #dddddd;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

/* .main-banner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  z-index: -1;
}

.main-banner::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-image: url(../../assets/img/dots.png);
  background-repeat: repeat;
  z-index: -1;
} */

.main-banner .scroll_down {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 50px;
  width: 30px;
  z-index: 5;
  bottom: 30px;
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 20px;
  cursor: pointer;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
  outline: none;
}

.main-banner .scroll_down::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: scroll_down_btn-animation 2s infinite;
  animation: scroll_down_btn-animation 2s infinite;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button.modal-video-close-btn {
  cursor: pointer;
}

.item-bg-one {
  background-image: url(../../assets/img/image-banner-4.webp);
}

.item-bg-two {
  background-image: url(../../assets/img/image-banner-2.webp);
}

.item-bg-three {
  background-image: url(../../assets/img/image-banner-10.webp);
}

.hero-content {
  text-align: center;
  max-width: 940px;
  margin: 50px auto 0;
}

.hero-content h5 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}

.hero-content h1 {
  color: #ffffff;
  font-size: 70px;
  font-weight: 700;
  margin: 15px 0;
  text-transform: uppercase;
}

.hero-content h1 span {
  color: var(--lila);
}

.hero-content p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 30px;
}

.hero-content .video-btn {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff !important;
  margin-left: 7px;
  text-transform: capitalize;
  background: transparent;
  border: none;
  cursor: pointer;
}

.hero-content .video-btn i {
  font-size: 38px;
  position: relative;
  top: 8px;
  margin-right: 7px;
}

.hero-content .video-btn:hover,
.hero-content .video-btn:focus {
  color: var(--lila) !important;
}

.hero-content .btn {
  margin-right: 7px;
}

.opacityZero {
  opacity: 0;
}

.opacityOne {
  opacity: 1;
}

.creative-box1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
}

.creative-box1::before {
  content: "";
  border-right: 50vw solid #ffffff;
  border-top: 60px solid transparent;
  bottom: 0;
  right: 0;
  position: absolute;
  height: 0;
  width: 0;
}

.creative-box1::after {
  content: "";
  position: absolute;
  border-left: 50vw solid #ffffff;
  border-top: 60px solid transparent;
  bottom: 0;
  height: 0;
  left: 0;
  width: 0;
}

.animation-box1 {
  position: absolute;
  left: 100px;
  bottom: 100px;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.animation-box2 {
  position: absolute;
  right: 150px;
  top: 150px;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.animation-box3 {
  position: absolute;
  left: 100px;
  top: 100px;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.animation-box4 {
  position: absolute;
  right: -260px;
  bottom: -260px;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.animation-box4 img {
  max-width: 430px;
}

.animation-box5 {
  position: absolute;
  right: -150px;
  bottom: -150px;
  z-index: -1;
}

.animation-box6 {
  position: absolute;
  right: 5px;
  bottom: 15px;
  z-index: -1;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

.animation-box7 {
  position: absolute;
  left: 50%;
  bottom: 50px;
  z-index: -1;
  -webkit-animation: movebounce 3s linear infinite;
  animation: movebounce 3s linear infinite;
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.wave_box {
  height: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wave_box .wave {
  background: url(../../assets/img/wave.png) repeat-x;
  position: absolute;
  bottom: 0;
  width: 6400px;
  left: 0;
  height: 90px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.wave_box .wave:nth-of-type(2) {
  bottom: -30px;
  -webkit-animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  background: var(--lila);
  padding-top: 50px;
  font-family: "Ubuntu", sans-serif;
  padding-bottom: 50px;
  position: relative;
  position: relative;
  /* margin-top: -10px; */
}

.subscribe-area p {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 24px;
}

.subscribe-area .form-control {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 30px;
  color: #ffffff;
}

.subscribe-area .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.subscribe-area .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.subscribe-area .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.subscribe-area .form-control::placeholder {
  color: #ffffff;
}

.subscribe-area button {
  position: absolute;
  right: 10px;
  top: 0;
  height: 45px;
  width: 75px;
  background: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 25px;
  cursor: pointer;
  line-height: 50px;
  color: var(--lila);
}

.subscribe-area .validation-danger,
.subscribe-area .validation-success {
  color: #ffffff;
  margin-top: 10px;
}

/* .subscribe-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25px;
  background-position: center center;
  background-repeat: repeat;
  background-size: contain;
  -webkit-transform: rotate(540deg);
  transform: rotate(540deg);
} */

/*================================================
Welcome Area CSS
=================================================*/
.single-box {
  text-align: center;
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 30px 20px;
  background: #ffffff;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-box i {
  font-size: 50px;
  color: #999999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-box:hover i {
  color: var(--rosa);
}

.single-box h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 20px;
}

.single-box p {
  margin-bottom: 0;
}

.single-box::before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  border-radius: 0 0 5px 5px;
  left: 0;
  background: var(--lila);
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-box:hover::before {
  width: 100%;
}

.single-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.about-area .row {
  position: relative;
}

.about-area .animation-box2 {
  right: 80px;
  top: 50px;
}

.about-image {
  position: absolute;
  left: -90px;
  top: -50px;
}

.about-title h3 {
  font-size: 30px;
  margin: 0 0 10px;
  font-weight: 400;
}

.about-title h5 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 300;
  margin: 0 0 15px;
}

.about-title h5 span {
  color: var(--lila);
  font-weight: 400;
}

.about-title p {
  margin-bottom: 0;
}

.our-skill {
  margin-top: 25px;
}

.our-skill .single-progress .progress-title {
  font-size: 16px;
  margin: 0 0 8px;
}

.our-skill .single-progress .progress {
  height: 10px;
  background: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: visible;
}

.our-skill .single-progress .progress .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  background: var(--azul);
  border-radius: 10px;
  -webkit-animation: animate-positive 2s;
  animation: animate-positive 2s;
}

.our-skill .single-progress .progress .progress-value {
  font-size: 15px;
  font-weight: 400;
  color: #222222;
  font-family: "Ubuntu", sans-serif;
  position: absolute;
  top: -28px;
  right: 0;
}

.our-skill .single-progress:nth-child(4) .progress {
  margin-bottom: 0;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.our-mission p {
  margin-bottom: 0;
  margin-top: 12px;
}

.why-choose-us {
  text-align: center;
  background: #ffffff;
  margin-top: 20px;
}

.why-choose-us .single-choose {
  padding: 20px 15px;
}

.why-choose-us .single-choose i {
  font-size: 35px;
  color: #777777;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.why-choose-us .single-choose:hover i {
  color: var(--rosa);
}

.why-choose-us .single-choose h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 8px;
}

.why-choose-us .col-lg-4:nth-child(1) .single-choose {
  border-bottom: 1px solid #eeeeee;
}

.why-choose-us .col-lg-4:nth-child(2) .single-choose {
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.why-choose-us .col-lg-4:nth-child(3) .single-choose {
  border-bottom: 1px solid #eeeeee;
}

.why-choose-us .col-lg-4:nth-child(5) .single-choose {
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 70px;
  background-image: url(../../assets/img/bg.png);
  background-attachment: fixed;
  background-repeat: repeat;
}

.single-services {
  padding: 30px 20px;
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-services i {
  font-size: 45px;
  color: #999999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-services:hover i {
  color: var(--rosa);
}

.single-services h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 20px;
}

.single-services p {
  margin-bottom: 0;
}

.single-services .bg-number {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 54px;
  color: var(--azul);
  opacity: 0.25;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-services:hover .bg-number {
  opacity: 1;
}

.single-services:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  position: relative;
  overflow: hidden;
}

.team-area .animation-box4 {
  right: -300px;
  bottom: -300px;
}

.single-team {
  text-align: center;
}

.single-team img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 320px;
  width: 240px;
}

.single-team ul {
  margin: 0;
  list-style-type: none;
  display: inline-block;
  background: var(--azul);
  position: relative;
  top: -20px;
  padding: 6px 30px;
  border-radius: 30px;
}

.single-team ul li {
  display: inline-block;
}

.single-team ul li a {
  color: #ffffff;
  font-size: 15px;
  margin: 0 -3px;
  width: 33px;
  border-radius: 50%;
  border: 1px solid transparent;
  height: 33px;
  text-align: center;
  line-height: 33px;
}

.single-team ul li a:hover {
  border-color: #ffffff;
  background: #ffffff;
  color: var(--rosa);
}

.single-team h3 {
  font-weight: 500;
  font-size: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.single-team span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #999999;
}

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
  padding-bottom: 60px;
}

.single-work {
  margin-bottom: 40px;
}

.single-work .work-image {
  display: block;
  position: relative;
}

.single-work .work-image::before {
  content: "";
  background: var(--azul);
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-work .work-image:hover::before,
.single-work .work-image:focus::before {
  width: 100%;
}

.single-work .work-image .popup-btn {
  position: absolute;
  left: 0;
  top: 50%;
  text-align: center;
  right: 0;
  cursor: pointer;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 45px;
  margin: 0 auto;
  height: 45px;
  line-height: 40px;
  border-radius: 50%;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
}

.single-work .work-image .popup-btn:hover {
  background: #ffffff;
  color: var(--azul);
}

.single-work .work-image:hover .popup-btn,
.single-work .work-image:focus .popup-btn {
  opacity: 1;
  visibility: visible;
  margin-top: -15px;
}

.single-work h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 25px 0 0;
  line-height: 12px;
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #4545a0;
  /* $template_color */
}

.features-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.2;
  width: 100%;
  background: url(../../assets/img/white-waves.png);
}

.features-img img {
  border-radius: 10px;
}

.single-features {
  position: relative;
  margin-bottom: 30px;
  padding-left: 90px;
}

.single-features .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  color: var(--azul);
  height: 70px;
  text-align: center;
  line-height: 70px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50%;
  font-size: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-features:hover .icon {
  background: transparent;
  color: #ffffff;
}

.single-features h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 15px;
}

.single-features p {
  color: #fff;
  margin-bottom: 0;
  font-weight: 400;
}

.single-features:nth-child(3) {
  margin-bottom: 0;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  position: relative;
  overflow: hidden;
}

.single-feedback {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 5px;
  margin-top: 25px;
  z-index: 1;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback:hover {
  background: var(--azul);
}

.single-feedback .client-info {
  position: relative;
  padding-left: 118px;
  margin-bottom: 20px;
}

.single-feedback .client-info .img {
  position: absolute;
  left: 0;
  top: -45px;
  width: 100px;
}

.single-feedback .client-info .img img {
  border-radius: 0 20px 0 0;
}

.single-feedback .client-info h4 {
  font-size: 20px;
  font-weight: 500;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback .client-info span {
  display: block;
  font-size: 14px;
  color: #828893;
  margin-top: 8px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback p {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 0;
}

.single-feedback:hover p,
.single-feedback:hover .client-info h4,
.single-feedback:hover .client-info span {
  color: #fff;
}

.single-feedback i {
  font-size: 104px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #6f8ba4;
  z-index: -1;
  margin-top: -6px;
  margin-left: -70px;
  opacity: 0.07;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-feedback:hover i {
  color: #ffffff;
  opacity: 0.14;
}

/*================================================
Pricing Area CSS
=================================================*/
.tab-slider-nav .tab-slider .nav-tabs {
  display: block;
  text-align: center;
  margin-bottom: 40px;
  border: none;
}

.tab-slider-nav .tab-slider .nav-tabs a {
  display: inline-block;
  background: #eeeeee;
  border: none;
  border-radius: 30px;
  margin: 0 5px;
  font-weight: 600;
  font-size: 15px;
  padding: 9px 35px;
  color: #686868;
}

.tab-slider-nav .tab-slider .nav-tabs a.active {
  background: var(--azul);
  color: #ffffff;
}

.pricing-table {
  text-align: center;
  background-image: url(../../assets/img/bg.png);
  padding-bottom: 35px;
  border-radius: 5px;
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  border: 1px solid transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.pricing-table .pricing-header {
  padding: 40px 20px;
  border-bottom: 1px solid #e4e4e4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
}

.pricing-table .price {
  font-size: 34px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 25px;
  color: var(--azul);
}

.pricing-table .pricing-features {
  padding: 0;
  margin: 0 0 35px;
  list-style-type: none;
}

.pricing-table .pricing-features li {
  font-size: 15px;
  font-weight: 400;
  color: #777777;
  margin-bottom: 12px;
}

.pricing-table .pricing-features li:last-child {
  margin-bottom: 0;
}

.pricing-table .btn-primary {
  background: transparent;
  text-transform: uppercase;
  color: #777777;
  border: 1px solid var(--azul);
}

.pricing-table .btn-primary:hover,
.pricing-table .btn-primary:focus {
  background: var(--azul) !important;
  border-color: var(--azul) !important;
}

.pricing-table.active,
.pricing-table:hover {
  border: 1px solid var(--azul);
}

.pricing-table.active .pricing-header,
.pricing-table:hover .pricing-header {
  background: var(--azul);
  border-color: var(--azul);
  color: #ffffff;
}

.pricing-table.active .btn-primary,
.pricing-table:hover .btn-primary {
  background: var(--azul);
  color: #ffffff;
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  padding-bottom: 70px;
}

.single-blog-post {
  margin-bottom: 30px;
}

.single-blog-post .post-thumbnail {
  overflow: hidden;
}

.single-blog-post .post-thumbnail a {
  display: block;
}

.single-blog-post .post-thumbnail a img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-post:hover .post-thumbnail a img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.single-blog-post .post-content {
  margin-top: 25px;
}

.single-blog-post .post-content h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
}

.single-blog-post .post-content h3 a {
  color: #000000;
}

.single-blog-post .post-content h3 a:hover {
  color: var(--azul);
}

.single-blog-post .post-content .read-more {
  font-size: 15px;
  color: #777777;
}

.single-blog-post .post-content .read-more:hover {
  color: var(--azul);
}

/*================================================
Work With Us Area CSS
=================================================*/
.work-with-us {
  background-color: var(--azul);
  z-index: 1;
  text-align: center;
  position: relative;
}

.work-with-us::before {
  content: "";
  position: absolute;
  left: 0;
  opacity: 0.2;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/img/regal.png);
  z-index: -1;
}

.work-with-us h3 {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 12px;
  margin-top: -7px;
}

.work-with-us span {
  font-size: 20px;
  color: #ffffff;
  display: block;
  margin-bottom: 25px;
}

.work-with-us .btn-primary {
  color: #6b6bce;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.work-with-us .btn-primary:hover,
.work-with-us .btn-primary:focus {
  color: #ffffff;
  background: transparent;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  text-align: center;
  overflow: hidden;
  position: relative;
}

.partner-area .item a {
  display: block;
  position: relative;
  z-index: 1;
}

.partner-area .owl-carousel .owl-item img {
  width: 140px;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-box {
  -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
  padding-left: 115px;
  padding-right: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

.contact-box .icon {
  position: absolute;
  left: 25px;
  top: 30px;
  width: 70px;
  height: 70px;
  line-height: 68px;
  font-size: 35px;
  text-align: center;
  border-radius: 50%;
  color: var(--lila);
  border: 1px solid var(--lila);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.contact-box:hover .icon {
  background: var(--lila);
  color: #ffffff;
}

.contact-box h4 {
  font-size: 18px;
  margin-bottom: 12px;
}

.contact-box p {
  margin: 0;
  line-height: 25px;
  font-weight: 400;
}

.contact-box p a {
  color: #777777;
}

.contact-box p a:hover {
  color: var(--lila);
}


/* Tech Stack */

.contact-box-tech {
  -webkit-box-shadow: 10px 10px 20px 0 rgba(72, 73, 121, 0.15);
  box-shadow: 2px 2px 10px 0 rgba(72, 73, 121, 0.15);
  padding-left: 120px;
  padding-right: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}


.contact-box-tech .icon {
  position: absolute;
  left: 25px;
  top: 30px;
  width: 70px;
  height: 70px;
  line-height: 68px;
  font-size: 35px;
  text-align: center;
  border-radius: 50%;
  color: var(--lila);
  border: 1px solid  var(--lila);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.contact-box-tech:hover .icon {
  background: var(--lila);
  color: #ffffff;
}

.contact-box-tech h4 {
  font-size: 18px;
  margin-bottom: 12px;
}

.contact-box-tech p {
  margin: 0;
  line-height: 25px;
  font-weight: 400;
  font-size: 10px;
}

.contact-box-tech p a {
  color: #777777;
}

.contact-box-tech p a:hover {
  color: var(--lila);
}

/* Finish tech */

.get-in-touch {
  margin-top: 40px;
}

.get-in-touch h3 {
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.get-in-touch p {
  margin-bottom: 20px;
}

.get-in-touch ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.get-in-touch ul li {
  display: inline-block;
  margin-right: 10px;
}

.get-in-touch ul li:last-child {
  margin-right: 0;
}

.get-in-touch ul li a {
  color: var(--rosa);
}

#contactForm {
  margin-top: 40px;
}

#contactForm .form-control {
  background: transparent;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

#contactForm .list-unstyled li {
  color: red;
}

#contactForm #msgSubmit {
  margin: 0;
}

#contactForm #msgSubmit.text-left {
  margin-top: 20px;
  font-size: 18px;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding: 30px 0;
  background: #121314;
}

.footer-area p {
  font-size: 14px;
  margin-bottom: 0;
  color: #d5d5d5;
}

.footer-area ul {
  text-align: right;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-area li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  padding-left: 20px;
}

.footer-area li::before {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  background: #ffffff;
  width: 2px;
  height: 12px;
}

.footer-area li:first-child::before {
  display: none;
}

.footer-area li a {
  color: #d5d5d5;
}

.footer-area li a:hover {
  color: var(--azul);
}

/*================================================
Right Side Modal CSS
=================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 520px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-dialog .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
}

.modal.right .modal-dialog .modal-content .modal-body {
  padding: 20px;
}

.modal.right .modal-dialog .modal-content .modal-body p {
  margin-top: 20px;
}

.modal.right .modal-dialog .modal-content .modal-body ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.modal.right .modal-dialog .modal-content .modal-body .modal-contact-info {
  margin-top: 30px;
  margin-bottom: 20px;
}

.modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li {
  position: relative;
  padding-left: 45px;
  font-size: 18px;
  margin-bottom: 25px;
}

.modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li b {
  display: block;
}

.modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 35px;
  color: var(--rosa);
}

.modal.right .modal-dialog .modal-content .modal-body .social-links li {
  display: inline-block;
}

.modal.right .modal-dialog .modal-content .modal-body .social-links li a {
  color: var(--lila);
  margin-right: 10px;
  font-size: 18px;
}

.modal.right .modal-dialog .modal-content .modal-body .logo a {
  color: var(--azul);
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 900;
}

.modal.right .modal-dialog .modal-content .modal-body .logo a span {
  color: var(--azul);
}

.modal.right .modal-dialog .modal-content .modal-header {
  border-bottom-color: transparent;
  background-color: transparent;
  padding: 20px 30px 0;
}

.modal.right .modal-dialog .modal-content .modal-header .close {
  color: #000000;
  font-size: 35px;
  outline: 0 !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.modal.right
  .modal-dialog
  .modal-content
  .modal-header
  .close:not(:disabled):not(.disabled):focus,
.modal.right
  .modal-dialog
  .modal-content
  .modal-header
  .close:not(:disabled):not(.disabled):hover {
  color: var(--lila);
  text-decoration: none;
  opacity: 1;
}

.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

/*================================================
Got Top CSS
=================================================*/
.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
  background-color: var(--azul) !important;
}

.ScrollUpButton__Container {
  line-height: 1;
}

.ScrollUpButton__Container:focus {
  outline: 0;
}

#logoDing {
  color: var(--azul);
}

#logoDing div {
  width: 50px;
  height: 50px;
  margin-right: 0.8rem;
}


#titleUnic {
  margin-bottom: 0;
  margin-top: 15px;
}

#p_unic {
  margin-top: -0.8rem;
  color: var(--azul);
  margin-bottom: 3rem;
}

.work-with-us .container h4 {
  color: #ececec;
}

#githubIcon {
  position: relative;
  bottom: -2px;
}

/* New addings - changeLanguageButton with hover */

.changeLanguageButton {
  display: flex;
  color: var(--azul);
  background-color: transparent;
  position: relative;
  padding: 5px;
  top: 0px;
  cursor: pointer;
}

.changeLanguageButton:hover {
  color: white;
}


.changeLanguageButton {
  color: var(--rosa);
}

#logoDing #logotipo {
  width: 200px;
  position: absolute;
}
 .nav-item {
   display: flex;
   align-items: center;
 }


.hero-content h1 .gColor {
  color: white;
}

.logoBanner {
  margin: 0 auto;
  max-width: 200px;
  height: auto;
}